import React, {Component} from 'react';

// Elements
import AboutUsThreeSection from "../elements/about-us-3";
import TeamTwoSection from "../elements/team-2";

// Images
import bnrImg from "../../images/banner/bnr5.jpg";

class AboutUs3 extends Component{
	
	render(){
		return (
			<>
			
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>За Нас</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
								</nav>
							</div>
						</div>
					</div>

					<TeamTwoSection />

					<AboutUsThreeSection />
					
				</div>
				
			</>
		);
	}
}

export default AboutUs3;