import React, {Component} from 'react';
import Faq1 from '../elements/faq-1'

// Images
import bnrImg from "../../images/banner/bnr2.jpg";

class Faq extends Component{
	
	render(){
		return (
			<>	

				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Въпроси</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">									
								</nav>
							</div>
						</div>
					</div>
					
					<Faq1 />		
				</div>
				
			</>
		);
	}
}

export default Faq;