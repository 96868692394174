import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import logo2 from '../../images/logo2.png';
import icon1 from '../../images/icon/contact/icon1.png';
import icon2 from '../../images/icon/contact/icon2.png';
import icon3 from '../../images/icon/contact/icon3.png';

class aboutSection extends Component{
	render(){
		return(
			<>
				
				<footer className="footer">
					<div className="footer-info bg-primary">
						<div className="container">
							<div className="row align-items-center">
								{/* <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
									<div className="footer-logo">
										<img src={logo2} alt=""/> 
									</div>
								</div> */}
								<div className="col-lg-4 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon3} alt=""/></span> 
										</div>
										<div className="icon-content">
											<p>гр.Велико Търново</p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon1} alt=""/></span> 
										</div>
										<div className="icon-content">
											<p><a href="tel:+359 876 333 149">0876 333 149</a><br/><a href="tel:+359 876 333 249">0876 333 249</a></p>
										</div>
									</div>
								</div>
								<div className="col-lg-4 col-md-6 col-sm-6 mb-30">
									<div className="feature-container left footer-info-bx">
										<div className="icon-lg">
											<span className="icon-cell"><img src={icon2} alt=""/></span> 
										</div>
										<div className="icon-content">
											<a href = "mailto: American01Auto@gmail.com">American01Auto@gmail.com</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-top bt0">
						<div className="container">
							<div className="row">
								<div className="col-xl-3 col-md-6">
									<div className="widget footer_widget">
										<h5 className="footer-title">American AUTO</h5>
										<p className="mb-20"></p>
										<div className="ft-content">
											<i className="fa fa-phone"></i>
											<span>Обади ни се !</span>
											<h4><a href="tel:+359 876 333 149">0876 333 149</a></h4>
											<h4><a href="tel:+359 876 333 249">0876 333 249</a></h4>
										</div>
									</div>
								</div>
								<div className="col-xl-3 col-md-6">
									<div className="widget widget-link-2">
										<h5 className="footer-title">Още</h5>
										<ul>
											<li><Link to="/booking">Запитване</Link></li>
											<li><Link to="/services">Услуги</Link></li>
											<li><Link to="/about">За Нас</Link></li>
											<li><Link to="/faq">Въпроси</Link></li>										
										</ul>							
									</div>
								</div>
								
								<div className="col-xl-3 col-md-6">
									<div className="widget widget_info">
										<ul className="list-inline ft-social-bx">
											<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/AmericanAUTO21/" className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
											{/* <li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/" className="btn button-sm"><i className="fa fa-instagram"></i></a></li> */}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
									<p className="mb-0">© Copyright 2023. All right reserved.</p>
								</div>

							</div>
						</div>
					</div>
				</footer>
			
			</>
		);
	}
}

export default aboutSection;