import React, { Component } from 'react';

// Images
import bg1 from "../../images/background/bg1.png";
import aboutCar from "../../images/about/about-car.png";

class AboutUsOneSection extends Component{
	render(){
		return(
			
			<>
				<section className="section-area section-sp3 about-wraper2 bg-gray" style={{backgroundImage: "url("+bg1+")", backgroundRepeat: "no-repeat"}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-12 col-sm-12 col-12 text-center">
								<div className="heading-bx text-center">
									{/* <h6 className="title-ext mb-0" >Ние сме екип специализирана в вноса на автомобили от Америка,
									 предоставяйки висококачествени услуги за нашите клиенти.
									 Нашата мисия е да предложим най-добрите автомобили от най-големите пазари в света,
									  правейки ги достъпни за нашите клиенти.</h6>									 */}
								</div>
								<div className="about-content text-center">
									<div className="ttr-media">
										<img src={aboutCar} alt=""/>
									</div>
									<div className="content-info">
										<div className="content-left">
											<div className="service-content">
												<h6 className="title">Пълно обслужване</h6>
											</div>
											<div className="service-content">
												<h6 className="title">Части</h6>
											</div>
											<div className="service-content">
												<h6 className="title">Бояджийство и Тенекеджийство</h6>
											</div>	
										</div>
										<div className="content-right">
											<div className="service-content">
												<h6 className="title">Диагностика</h6>
											</div>
											<div className="service-content">
												<h6 className="title">Детайлинг и Аерография</h6>
											</div>
											<div className="service-content">
												<h6 className="title">Гуми и Джанти</h6>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default AboutUsOneSection;