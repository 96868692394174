import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// Elements
import FeatureOneSection from "../elements/feature-bx1";
import PartnerShipsSection from "../elements/partnerships";
import Servoces1 from "../elements/services-1";

// Images
import bnrImg from "../../images/banner/bnr3.jpg";

class Services extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Услуги</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									{/* <ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Начало</Link></li>
									</ul> */}
								</nav>
							</div>
						</div>
					</div>
					
					<FeatureOneSection />
										
					{/* <Servoces1 />  Разкоментирай това ако искаш да видиш на БГ. На БЪЛГАРСКИ */}

					<PartnerShipsSection />

				</div>
				
				
			</>
		);
	}
}

export default Services;