import React, { Component } from 'react';


import icon1 from "./../../images/icon/business/light.png"
import icon2 from "./../../images/icon/business/goal.png"
import icon3 from "./../../images/icon/business/growth.png"
import icon4 from "./../../images/icon/business/handshake.png"

class FeatureTwoSection extends Component{
	render(){
		return(
			<>
				<section className="section-area section-sp1">
					<div className="container">
					<div className="heading-bx text-center">
							{/* <h1  className="title-ext text-primary">Стъпка 1</h1> */}
							<h2 className="title mb-0">Защо да изберете нас?</h2>							
						</div>
						<div className="row">
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2">
									<div className="icon-lg text-primary">
										<img src={icon1}/>										
										{/* <i className="flaticon-fuel-1"></i> */}
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Прозрачност</h5>
										<p>Предлагаме абсолютна прозрачност по време на процеса за търга и вноса на автомобил. Без скрити условия!</p>										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2">
									<div className="icon-lg text-primary">
									<img src={icon2}/>	
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Изгодни Цени</h5>
										<p>Спестете между 20% до 30% от пазарната цена в Европа на избран от вас автомобил!</p>										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2">
									<div className="icon-lg text-primary">
									<img src={icon3}/>	
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Безплатен Преглед</h5>
										<p>Получавате безплатенен преглед, както и диагностика на автомобила.</p>										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-md-6 mb-30">
								<div className="feature-container feature-bx2">
									<div className="icon-lg text-primary">
									<img src={icon4}/>	
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Доверие</h5>
										<p>Притежаваме всички необходими бизнес лицензи и партньорства за закупуване на всякакъв вид превозни средства.</p>										
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default FeatureTwoSection;