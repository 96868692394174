import React, {Component} from 'react';
import { Link } from 'react-router-dom';

class Error404 extends Component{
	
	render(){
		return (
			<>
				<div className="error-404">
					<div className="inner-content container">
						<h2 className="error-title">4<span></span>4</h2>
						<h3 className="error-text">Страницата, която търсехте, не беше намерена.</h3>
						<p>Страницата, която търсите, може да е била премахната, името ѝ да е променено или временно да не е достъпна.</p>
						<div className="clearfix">
							<Link to="/" className="btn btn-primary">Назад</Link>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Error404;