import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import bg2 from "../../images/background/bg2.png";

class FeatureOneSection extends Component{
	render(){
		return(
			<>
				<section className="section-area bg-gray section-sp1" style={{backgroundImage: "url("+bg2+")", backgroundRepeat: "no-repeat", backgroundPosition: "right top"}}>
					<div className="container">
						<div className="row">
						<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-check-2"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Диагностика</h5>
										<p>Диагностика на всички компоненти. Прочитане на всички грешки, евенти и живи данни.
											Кодиране на модули и части.</p>
										{/* <Link to="/service-engine-diagnostics" className="btn-link">Прочети Повече</Link> */}
									</div>
									
								</div>
								
							</div>
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-fuel-1"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Смяна на масло</h5>
										<p>Обслужване масло, филтри и консумативи.</p>
										{/* <Link to="/service-lube-oil-and-filters" className="btn-link">Прочети Повече</Link> */}
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-brake"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Спирачна система</h5>
										<p>Обслужване на спирачна система. Смяна дискове, спирачки и всичко по периферията.</p>
										
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-car-4"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Цялостен Преглед</h5>
										<p>Цялостен преглед на подемник за състоянието на автомобила.</p>
										{/* <Link to="/service-belts-and-hoses" className="btn-link">Прочети Повече</Link> */}
									</div>
								</div>
							</div>
						
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-repair-1"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Гуми и Джанти</h5>
										<p>Смяна, продажба на гуми, изправяне на джанти и диагностика на TPMS проблеми.</p>
										{/* <Link to="/service-engine-diagnostics" className="btn-link">Прочети Повече</Link> */}
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-battery-2"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Акумулатор</h5>
										<p>Проверка и смяна на акумулатор.</p>
									</div>
								</div>
							</div>
						
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-paint"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Бояджийство и тенекеджийство</h5>
										
									</div>
								</div>
							</div>
							
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-engine"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Двигател</h5>
										<p>Обслужване и ремонт на двигатели.</p>
										{/* <Link to="/service-lube-oil-and-filters" className="btn-link">Прочети Повече</Link> */}
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-check-2"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Застраховка и Автокаско</h5>
										<p>Сключване на Застраховка и Каско с преференциални условия.</p>
										
									</div>
									
								</div>
								
							</div>
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-check-2"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">Авточасти</h5>
										<p>There are many variations of passages of Lorem Ipsum available.</p>
										{/* <Link to="/service-air-conditioning" className="btn-link">Прочети Повече</Link> */}
									</div>
									
								</div>
								
							</div>
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-check-2"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">ТехноТест</h5>
										<p>There are many variations of passages of Lorem Ipsum available.</p>
										{/* <Link to="/service-air-conditioning" className="btn-link">Прочети Повече</Link> */}
									</div>
									
								</div>
								
							</div>
							<div className="col-xl-3 col-lg-4 col-sm-6 mb-30">
								<div className="feature-container feature-bx1">
									<div className="icon-lg mb-10 text-primary">
										<i className="flaticon-check-2"></i>
									</div>
									<div className="icon-content">
										<h5 className="ttr-tilte">CarFax</h5>
										<p>There are many variations of passages of Lorem Ipsum available.</p>
										{/* <Link to="/service-air-conditioning" className="btn-link">Прочети Повече</Link> */}
									</div>
									
								</div>
								
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default FeatureOneSection;