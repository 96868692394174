import React, { Component } from 'react';
import slide3 from "../../../images/slider/slide3.jpg"
import car3 from "../../../images/slider/car3.png"

class SliderOne extends Component{
	render(){
		
		return(
			<>
				<div className="ttr-slider style-2">
					
						<div className="slider-item">								
							<div className="slider-img">
								<img src={slide3} alt=""/>
							</div>
							<div className="slider-content container text-center text-white">
								<div className="content-inner">
									<h6 className="sub-title">Внос на автомобили  от САЩ & Канада</h6>
									<h2 className="title">American AUTO</h2>
									<div className="car-wheel wheel2"  data-swiper-parallax="-600">
										<img src={car3} alt=""/>
									</div>
								</div>
							</div>
						</div>
				</div>
				
			</>
		);
	}
}

export default SliderOne;