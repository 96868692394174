import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import PartnerShipsSection from "../elements/partnerships";

// Images
import bnrImg from "../../images/banner/bnr1.jpg";

class ContactUs2 extends Component{
	
	render(){
		return (
			<>
				
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark" style={{backgroundImage: "url("+bnrImg+")"}}>
						<div className="container">
							<div className="page-banner-entry text-center">
								<h1><span>Контакти</span></h1>
								<nav aria-label="breadcrumb" className="breadcrumb-row">
									{/* <ul className="breadcrumb">
										<li className="breadcrumb-item"><Link to="/"><i className="las la-home"></i>Начало</Link></li>
										<li className="breadcrumb-item active" aria-current="page">За нас</li>
									</ul> */}
								</nav>
							</div>
						</div>
					</div>
					
					<section className="section-area section-sp1">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-5 mb-50">
									<form className="contact-form style1 ajax-form" action="script/contact.php">
										<div className="heading-bx mb-4 text-white">
											<h6 className="title-ext text-white">Контактна форма</h6>
											<h3 className="title mb-0">Изпратете ни запитване</h3>
										</div>
										<div className="row">
											<div className="col-lg-12">
												<div className="form-group  mb-3">
													<div className="input-group">
														<input name="name" type="text" required className="form-control valid-character" placeholder="Име"/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group  mb-3">
													<div className="input-group"> 
														<input name="email" type="email" className="form-control" required  placeholder="Имейл"/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group mb-3">
													<div className="input-group">
														<input name="phone" type="text" required className="form-control int-value" placeholder="Телефон"/>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<div className="form-group mb-3">
													<div className="input-group">
														<textarea name="message" rows="4" className="form-control" placeholder="Съобщение" required ></textarea>
													</div>
												</div>
											</div>
											<div className="col-lg-12">
												<button name="submit" type="submit" value="Submit" className="btn btn-secondary btn-lg"> Изпрати</button>
											</div>
										</div>
									</form>
								</div>
								<div className="col-lg-7 mb-30">
									<div className="contact-ri">
										<div className="heading-bx mb-4">
											<h6 className="title-ext text-primary">Контактна информация</h6>
											<h2 className="title mb-0">Свържете се с нас <br/>за повече информация</h2>
										</div>
										<div className="row">
											<div className="col-md-6 col-sm-6 mb-15">
												<div className="icon-box">
													<h6 className="title"><i className="ti-map-alt"></i>Локация</h6>		
													<p>бул. „България“ 72, 5005 ж.к. Колю Фичето, Велико Търново</p>
												</div>
												
											</div>
											<div className="col-md-6 col-sm-6 mb-15">
												<div className="icon-box-2">
													<h6 className="title"><i className="ti-id-badge"></i>Имейл</h6>		
													<a href = "mailto: American01Auto@gmail.com">American01Auto@gmail.com</a>
													{/* <p>----------</p> */}
												</div>
											</div>
											<div className="col-lg-12 mb-15">
												{/* <div className="icon-box">
													<h6 className="title"><i className="ti-world"></i>Follow Us</h6>
													<ul className="social-media">
														<li><a target="_blank" href="https://www.facebook.com/AmericanAUTO21" rel="noreferrer" className="btn btn-primary"><i className="fa fa-facebook"></i></a></li>
														<li><a target="_blank" href="https://www.google.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-google-plus"></i></a></li>
														<li><a target="_blank" href="https://www.linkedin.com/" rel="noreferrer" className="btn btn-primary"><i className="fa fa-linkedin"></i></a></li>
													</ul>
												</div> */}
											</div>
										</div>
										{/* <ul className="brand-list">
											<li><img src={car1} alt=""/></li>
											<li><img src={car2} alt=""/></li>
											<li><img src={car3} alt=""/></li>
											<li><img src={car4} alt=""/></li>
											<li><img src={car5} alt=""/></li>
											<li><img src={car6} alt=""/></li>
										</ul> */}
									</div>
								</div>
							</div>
						</div>
					</section>
					<div className="container">
						<PartnerShipsSection />
					</div>
		
				</div>
				
			</>
		);
	}
}

export default ContactUs2;