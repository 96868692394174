import React, {Component} from 'react';
import {Accordion} from 'react-bootstrap';

import copart from "../../images/gallery/copart-logo.png";
import iaai from "../../images/gallery/iaa-insurance-auto-auctions-inc-logo-75C4EF61AE-seeklogo.com.png";

class Faq1 extends Component{
	render(){
		return(
			<>
				<section className="section-area section-sp3">
					   <div className="container">
							<div className="row">
								<div className="col-lg-2"></div>
								<div className="col-lg-8">
								<Accordion className="accordion ttr-accordion style1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Къде може да разгледам Автомобили и други МПС-та?
											</Accordion.Header>
											<Accordion.Body>
												<p>Може да разгледате в следните сайтове:</p>
												<div className="row">
													<div className="col-6">
													<p className="mb-0"><a className="text-black" href="http://www.iaai.com" target="_blank"> <img src={iaai}/></a></p>
													</div>
													<div className="col-6">
													<p><a className="text-black" href="http://www.copart.com" target="_blank"><img src={copart}/></a></p>
													</div>
												</div>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Проверка на автомобил ?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">След като изберете автомобил, ще проверим сервизната му история, брой собственици, обслужване и състоянието му през годините за да се уверим че това е правилния избор за Вас!
												Срещу заплащане индивидуални външни компании могат да направят обстоен преглед на място с видео клип и детайлни снимки.
												</p>  <a href="/src/documents/2C3CDZAG6LH148482.pdf" download>Свали Примерерна История/Carfax</a> 
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="2">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Частична загуба на МПС - Отговорност?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Вие може допълнително да застраховате превозното средство като заплатите застрахова и в случай на сухопътен или морски проблем да не сте ощетен.
												</p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="3">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Кой е отговорен за оформянето на документите и доставката?											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Ние ще се погрижем за целия процес като контролираме всеки един етап - Оформяне на документи и доставка. </p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="4">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Какви докумети ще получа?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Вие ще получите всички нужни документи както талона на МПС и документи за обмитяването на автомобила. Освен това можем да съдействаме с бързо изваждане на ТехноТест.</p>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>								
									<Accordion className="accordion ttr-accordion style1">
										<Accordion.Item eventKey="0">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Кои талони на автомобили НЕ могат да се регистрират в България?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">Parts Only  |  Non Repairable  |  Irreapairable  |  Wrecked  |  Junk  |  Junked  |  Destroyed  |  Scrapped  |  Unrebuildable  |  C. of Destruction 
												|  Non Rebuildable  |  Not Eligible for road use. </p>
											</Accordion.Body>
										</Accordion.Item>
										<Accordion.Item eventKey="1">
											<Accordion.Header>
												<i className="fa fa-question-circle"></i>
												Други въпроси?
											</Accordion.Header>
											<Accordion.Body>
												<p className="mb-0">За всички други въпроси може да използвате формата за контакт, да ни се обадите или пишете на нашата страница.</p>
												<div className="widget widget_info">
										<ul className="list-inline ft-social-bx">
											<li><a target="_blank" rel="noreferrer" href="https://www.facebook.com/AmericanAUTO21" className="btn button-sm"><i className="fa fa-facebook"></i></a></li>
											<li><a target="_blank" rel="noreferrer" href="https://www.instagram.com/" className="btn button-sm"><i className="fa fa-instagram"></i></a></li>
										</ul>
									</div>
											</Accordion.Body>

											<Accordion.Body>
												
											</Accordion.Body>
										
											<Accordion.Body>

											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</div>
								<div className="col-lg-2"></div>
							</div>							
						</div>
					</section>	
			</>
		);
	}
}

export default Faq1;