import React, {Component} from 'react';

// Elements
import SliderOneSection from "../elements/home-slider/slider1";
import AboutUsOneSection from "../elements/about-us-1";
import LatestBlogSection from "../elements/latest-blog-slider";
import FeatureTwoSection from "../elements/feature-bx2";
import PartnerShipsSection from "../elements/partnerships";
import ServicesSection from "../elements/schedule-service"

class Index extends Component{
	
	
	render(){
		
		return (
			<>
			
				<SliderOneSection />

				<LatestBlogSection />

				<FeatureTwoSection />
				
				<AboutUsOneSection />

				<PartnerShipsSection />

				<ServicesSection />
				
			</>
		);
	}
}

export default Index;