import React, { Component } from 'react';

// Images
import car1 from "../../images/why-choose/moto-pfohe.jpg";
import car2 from "../../images/why-choose/medina-med.jpg";
import car3 from "../../images/why-choose/lion-trans.png";
import car4 from "../../images/why-choose/stempo-trans.png";
import car5 from "../../images/why-choose/Daga-Avto.png";
import car6 from "../../images/why-choose/auto-vip.jpg";

class PartnerShipsSection extends Component{
	render(){
		return(
			<>
				<section className="section-area section-sp1 bg-white">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-xl-5 col-lg-5">
								<div className="heading-bx mb-4 pr-lg-5">
									<h6 className="title-ext m-b0 text-primary">Още за Нас!</h6>
									<h2 className="title">Нашите Партньори</h2>
								</div>
							</div>
							<div className="col-xl-7 col-lg-7 mb-30">
								<ul className="brand-list">
									<li><img src={car6} alt=""/></li>									
									<li><img src={car2} alt=""/></li>
									<li><img src={car3} alt=""/></li>
									<li><img src={car1} alt=""/></li>	
									<li><img src={car5} alt=""/></li>
									<li><img src={car4} alt=""/></li>																									
																		
								</ul>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default PartnerShipsSection;