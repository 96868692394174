import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import bg1 from "../../images/background/bg1.png";
import pic1 from "../../images/about/pic1.jpg";

class AboutUsOneSection extends Component{
	render(){
		return(
			<>
				
				<section className="section-area section-sp1 bg-white" style={{backgroundImage: "url("+bg1+")", backgroundRepeat:"no-repeat"}}>
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-5 mb-40">
								<div className="heading-bx mb-4">
									<h2 className="title">American Auto фирма с дългогодишен опит в продажбата на автомобили</h2>
									<p>- Внос на Автомобили от САЩ & Канада </p>
									<p>- Внос на мотори, лодки и други превозни средства </p>
									<p>- Транспорт до България </p>
									<p>- Технотест </p>
									<p>- Ремонт и обслужване</p>
								
									<p></p>
								</div>
							</div>
							<div className="col-lg-7 mb-30">							
								<div className="about-media">
									<div className="media">
										<img src={pic1} alt=""/>
									</div>
									<div className="about-contact bg-primary text-white">
										<h6 className="title-ext text-white">Искате да се свържете с нас?</h6>
										<h3 className="number mb-15 text-white"><a className="text-white" href="tel:+359 876 333 149">0876 333 149</a></h3>
										<h3 className="number mb-15 text-white"><a className="text-white" href="tel:+359 876 333 249">0876 333 249</a></h3>
										<p className="mb-20">Пригответе се да вдигнем оборотите, докато навлизаме в света на вноса на автомобили, където луксът, иновациите и производителността се сливат, за да подхранват вашите автомобилни мечти.</p>
										<Link to="/booking" className="btn btn-light">Запитване</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</>
		);
	}
}

export default AboutUsOneSection;